import { AxiosError } from 'axios';
import { edit, get } from '@/utils';
import { IVersion } from './types';

async function fetchVersions(
	page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IVersion[]> {
	try {
		const res = await get(`api/protected/releases?page=${page + 1}&items=${items}`);
		console.log('releases res', res);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { releases: IVersion[] };
		return data?.releases || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchSingleVersion(
	id: string
): Promise<IVersion> {
	try {
		const res = await get(`api/protected/releases/${id}`);
		console.log('releases res', res);
		const data = res.data as unknown as { release: IVersion };
		return data?.release || {} as IVersion;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}
async function fetchVersionQuery(
	query: string, page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IVersion[]> {
	try {
		const res = await get(`api/protected/releases${query}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { releases: IVersion[] };
		return data?.releases || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function patchVersion(
	id: string, required: boolean
): Promise<number | string> {
	try {
		const res = await edit(`api/protected/releases/${id}`, { required });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm datele.');
	}
}

export { fetchVersions, fetchSingleVersion, fetchVersionQuery, patchVersion };