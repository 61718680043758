import { AxiosError } from 'axios';
import { del, edit, get } from '@/utils';
import { FeedbackStatus, FeedbackType, IFeedback } from './types';

async function fetchFeedback(
	type: FeedbackType,
	page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IFeedback[]> {
	try {
		const res = await get(`api/protected/feedback?type=${type}&page=${page + 1}&items=${items}`);
		console.log('feedback res', res);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { feedbacks: IFeedback[] };
		return data?.feedbacks || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchSingleFeedback(
	id: string
): Promise<IFeedback> {
	try {
		const res = await get(`api/protected/feedback/${id}`);
		console.log('feedback res', res);
		const data = res.data as unknown as { feedback: IFeedback };
		return data?.feedback || {} as IFeedback;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}
async function fetchFeedbackQuery(
	query: string, page: number, items: number,
	onResponse?: (page: number, items: number) => void
): Promise<IFeedback[]> {
	try {
		const res = await get(`api/protected/feedback${query}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { feedbacks: IFeedback[] };
		return data?.feedbacks || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function patchFeedback(
	id: string, status: FeedbackStatus
): Promise<number | string> {
	try {
		const res = await edit(`api/protected/feedback/${id}`, { status });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm datele.');
	}
}

async function deleteFeedback(feedbackId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/feedback/${feedbackId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem feedbackul.');
	}
}

export { fetchFeedback, fetchFeedbackQuery, patchFeedback, deleteFeedback, fetchSingleFeedback };