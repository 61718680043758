import { Avatar, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext, UserRole } from '@/providers';
import { HeaderRightSide, HeaderRoleButton, HeaderUserName, StyledBadge } from '../styled';
import { SessionExpireTimer } from './SessionExpireTimer';

export const roleTranslation = (role: UserRole): string => {
	switch (role) {
		case 'admin':
			return 'admin';
		case 'superadmin':
			return 'superadmin';
		case 'moderator':
			return 'moderator';
		case 'fsp':
			return 'administrație';
		default:
			return 'Rol necunoscut';
	}
};

export const UserSide: FC = () => {
	const { user } = useAuthContext();
	const userName = `${user?.firstName} ${user?.lastName}`;
	const navigate = useNavigate();

	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(769));
	const ismobileOrSmaller = useMediaQuery(theme.breakpoints.down(500));

	return (
		<HeaderRightSide>

			<SessionExpireTimer />

			{!isTabletOrSmaller &&
				<StyledBadge
					onClick={() => navigate('/common/profile')}
					overlap='circular'
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					variant='dot'
					sx={{ cursor: 'pointer' }}
				>
					<Avatar alt={userName} src={user?.avatar || ''} />
				</StyledBadge>
			}
			<HeaderUserName>{userName.toUpperCase()}</HeaderUserName>

			{!ismobileOrSmaller &&
				<HeaderRoleButton size='small'>
					{roleTranslation(user?.role)?.toUpperCase()}
				</HeaderRoleButton>
			}

		</HeaderRightSide>
	);
};