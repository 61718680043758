import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from '../../header';
import { SideDrawer } from '../../side-drawer';
import { MainLayoutBase, MainLayoutContent } from './styled';
import { useAppSelector } from '@/utils';

interface MainLayoutProps {
	children?: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
	const { isDrawerOpen, isComplexFilterOpen, isSimpleFilterOpen } = useAppSelector((state) => state.utils);
	const { pathname } = useLocation();

	const headerSize: number | undefined = useMemo(() => {
		if (isSimpleFilterOpen && pathname.includes('chat')) {
			return 214;
		} if (isComplexFilterOpen && (
			pathname.includes('consultation') || pathname.includes('announcment'))) {
			return 268;
		}
		return undefined;

	}, [pathname, isComplexFilterOpen, isSimpleFilterOpen]);

	return (
		<MainLayoutBase isDrawerOpen={isDrawerOpen} headerSize={headerSize}>
			<SideDrawer />
			<Header />
			<MainLayoutContent>
				{children}
			</MainLayoutContent>
		</MainLayoutBase>
	);
};

MainLayout.defaultProps = {
	children: undefined
};
