import { AxiosError } from 'axios';
import { generateQueryString, get, post } from '@/utils';
import { IAdministrationRoom, IChatRoom, IMessage } from './types';
import { IChatQuery } from '../socket/types';
import { IThread, IThreadReply } from '@/hooks';

async function fetchActionChatRooms(): Promise<{ rooms: IChatRoom[], administrationRooms: IAdministrationRoom[] }> {
	try {
		const res = await get('api/chat/rooms');
		const data = res.data as unknown as { rooms: IChatRoom[], administrationRooms: IAdministrationRoom[] };
		return data || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}
async function fetchActionQueryChatRooms(query: IChatQuery): Promise<IChatRoom[]> {
	try {
		const queryParams = generateQueryString(query);
		const res = await get(`api/chat/rooms${queryParams}`);
		const data = res.data as unknown as { rooms: IChatRoom[] };
		return data?.rooms || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchActionRoomMessages(
	room: string,
	page: number,
	items: number
): Promise<IMessage[]> {
	try {
		const res = await get(`api/chat/messages?room=${room}&page=${page + 1}&items=${items}`);
		const data = res.data as unknown as { messages: IMessage[] };
		return (data.messages || []).reverse();
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm mesajele.');
	}
};

async function fetchActionRoomThreads(
	room: string,
	page: number,
	items: number
): Promise<IThread[]> {
	try {
		const res = await get(`api/chat/threads?room=${room}&page=${page + 1}&items=${items}`);
		const data = res.data as unknown as { threads: IThread[] };
		return (data.threads || []).reverse();
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm postarile.');
	}
};

async function fetchThreadReplyes(
	room: string,
	page: number,
	items: number
): Promise<IThreadReply[]> {
	try {
		const res = await get(`api/chat/replies?thread=${room}&page=${page + 1}&items=${items}`);
		const data = res.data as unknown as { replies: IThreadReply[] };
		return (data.replies || []).reverse();
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm răspunsurile.');
	}
};

async function fetchUpdateRoom(
	room: string,
): Promise<IChatRoom> {
	try {
		const res = await get(`api/chat/rooms/${room}`);
		const data = res.data as unknown as { room: IChatRoom };
		return data.room;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
};

async function fetchUpdateAdminisytrationRoom(
	room: string,
): Promise<IAdministrationRoom> {
	try {
		const res = await get(`api/chat/rooms/${room}`);
		const data = res.data as unknown as { room: IAdministrationRoom };
		return data.room;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
};

async function postUnreadRoom(
	room: string,
): Promise<number> {
	try {
		const res = await post(`api/chat/rooms/${room}/unread`, { id: room });
		return res.status;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
};

export {
	fetchActionChatRooms, fetchActionRoomMessages, fetchActionQueryChatRooms, fetchUpdateRoom, postUnreadRoom,
	fetchActionRoomThreads, fetchUpdateAdminisytrationRoom, fetchThreadReplyes
};