import { useCallback, useEffect, useState } from 'react';
import { IThread, IThreadReply, useThreads } from '@/hooks';
import { notify, stateSetter } from '@/utils';
import { fetchThreadReplyes } from './actions';

export interface SingleThreadReturnType {
	singleThreadData: IThread;
	setSingleThreadData: stateSetter<IThread>;
	loadingThread: boolean;
	selectedThread: string | undefined;
	setSelectedThread: stateSetter<string | undefined>;
	repliesData: IThreadReply[] | null;
	setRepliesData: stateSetter<IThreadReply[] | null>;
	loadingReplies: boolean;
	setLoadingReplies: stateSetter<boolean>;
	page: number;
	setPage: stateSetter<number>;
	items: number;
	setItems: stateSetter<number>;
	hasMore: boolean;
	setHasMore: stateSetter<boolean>;
	loadingMore: boolean;
	handleLoadMore: () => void;
}
export function useSingleThread(): SingleThreadReturnType {
	const [selectedThread, setSelectedThread] = useState<string | undefined>();
	const { threadsData, setThreadsData, loadingThreads } = useThreads<IThread>(selectedThread);
	const [repliesData, setRepliesData] = useState<IThreadReply[] | null>(null);
	const [loadingReplies, setLoadingReplies] = useState<boolean>(false);

	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(15);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [loadingMore, setLoadingMore] = useState<boolean>(false);
	// const removeThreadFile = (id: string): void => {
	// 	setThreadFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	// };

	// const removeEditedThreadFile = (id: string): void => {
	// 	setThreadEditFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	// };

	// const removeReplyFile = (id: string): void => {
	// 	setReplyFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	// };

	// const removeEditedReplyFile = (id: string): void => {
	// 	setReplyEditFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	// };

	// const editThread = useCallback(
	// 	async (threadId: string, editObj: IEditThread, onSuccess?: (newVal: IEditThread) => void) => {
	// 		try {
	// 			setLoadingEditThreads(true);
	// 			const convertThreads = (): FileList => {
	// 				const dataTransfer = new DataTransfer();

	// 				threadEditFiles?.forEach((el) => {
	// 					dataTransfer.items.add(el.file); // Add the File object
	// 				});

	// 				return dataTransfer.files;
	// 			};

	// 			const fileList = convertThreads();
	// 			const fileToSend = await postFiles(fileList);
	// 			const updateObject: IEditThread = {
	// 				...editObj,
	// 				attachments: [
	// 					...((editObj.attachments || []).map((el) => ({
	// 						url: el.url,
	// 						mimetype: el.mimetype,
	// 						originalname: el.originalname,
	// 						size: el.size,
	// 					}))),
	// 					...fileToSend,
	// 				]
	// 			};
	// 			const response = await patchThread(threadId, updateObject);
	// 			if (response === 200) {
	// 				onSuccess && onSuccess(updateObject);
	// 			};
	// 		} catch (message) {
	// 			notify.error(`${message}`);
	// 		} finally {
	// 			setLoadingEditThreads(false);
	// 		}
	// 	},
	// 	[threadEditFiles]
	// );

	// const editReply = useCallback(
	// 	async (replyId: string, editObj: IEditReply, onSuccess?: (newVal: IEditReply) => void) => {
	// 		try {
	// 			setLoadingEditReply(true);
	// 			const convertThreads = (): FileList => {
	// 				const dataTransfer = new DataTransfer();

	// 				replyEditFiles?.forEach((el) => {
	// 					dataTransfer.items.add(el.file); // Add the File object
	// 				});

	// 				return dataTransfer.files;
	// 			};

	// 			const fileList = convertThreads();
	// 			const fileToSend = await postFiles(fileList);
	// 			const updateObject: IEditReply = {
	// 				...editObj,
	// 				attachments: [
	// 					...((editObj.attachments || []).map((el) => ({
	// 						url: el.url,
	// 						mimetype: el.mimetype,
	// 						originalname: el.originalname,
	// 						size: el.size,
	// 					}))),
	// 					...fileToSend,
	// 				]
	// 			};
	// 			const response = await patchReply(replyId, updateObject);
	// 			if (response === 200) {
	// 				onSuccess && onSuccess(updateObject);
	// 			};
	// 		} catch (message) {
	// 			notify.error(`${message}`);
	// 		} finally {
	// 			setLoadingEditReply(false);
	// 		}
	// 	},
	// 	[replyEditFiles]
	// );

	const getReplies = useCallback(
		async (roomParam: string) => {
			try {
				setLoadingReplies(true);
				const response = await fetchThreadReplyes(roomParam, page, items);
				response?.length === items && setHasMore(true);
				setRepliesData(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingReplies(false);
			}
		},
		// eslint-disable-next-line
		[fetchThreadReplyes]
	);

	const getPaginatedReplies = useCallback(
		async (roomParam: string, pageParam: number) => {
			try {
				setLoadingMore(true);
				const response = await fetchThreadReplyes(roomParam, pageParam, items);
				setHasMore(response?.length === items);
				setRepliesData((prev) => ([...response, ...(prev || [])]));
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingMore(false);
			}
		},
		// eslint-disable-next-line
		[fetchThreadReplyes]
	);

	const handleLoadMore = useCallback(() => {
		hasMore && !loadingMore && !loadingReplies && setPage((prevPage) => prevPage + 1);
		// eslint-disable-next-line
	}, [hasMore, loadingMore, loadingReplies]);

	useEffect(() => {
		if (threadsData && threadsData._id && hasMore && !loadingMore && page !== 0) {
			getPaginatedReplies(threadsData._id, page);
		}
		// eslint-disable-next-line
	}, [page])

	useEffect(() => {
		if (threadsData && threadsData._id && !loadingThreads) {
			getReplies(threadsData._id);
		}
		// eslint-disable-next-line
	}, [threadsData, loadingThreads]);

	return {
		singleThreadData: threadsData || {} as IThread,
		setSingleThreadData: setThreadsData,
		loadingThread: loadingThreads,
		selectedThread,
		setSelectedThread,
		loadingReplies,
		repliesData,
		setLoadingReplies,
		setRepliesData,
		handleLoadMore,
		hasMore,
		items,
		loadingMore,
		page,
		setHasMore,
		setItems,
		setPage
	};
}