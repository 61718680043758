import { useCallback, useEffect, useState } from 'react';
import { IVersion, VersionReturnType } from './types';
import { notify, stateSetter } from '@/utils';
import { fetchSingleVersion, fetchVersionQuery, fetchVersions, patchVersion } from './actions';

export function useVersion<T extends IVersion | IVersion[]>(
	getOnRender: boolean | undefined = false,
	parsId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): VersionReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);
	const [refresh, setRefresh] = useState({});

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getVersions = useCallback(
		async (pageParam: number, itemsParam: number) => {
			try {
				setLoading(true);
				const response = await fetchVersions(pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getVersionsQuery = useCallback(
		async (query: string, queryPage: number, queryItems: number) => {
			try {
				setLoading(true);
				const response = await fetchVersionQuery(query, queryPage, queryItems, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getSingleVersion = useCallback(
		async (id: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleVersion(id);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editVersion = useCallback(
		async (id: string, required: boolean, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await patchVersion(id, required);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să actualizăm datele');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getOnRender && queryParam === undefined) {
			getVersions(page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, getOnRender, page, items, refresh]);

	useEffect(() => {
		if (parsId) {
			getSingleVersion(parsId);
		}
		// eslint-disable-next-line
	}, [parsId]);

	useEffect(() => {
		if (queryParam && parsId === undefined) {
			getVersionsQuery(queryParam, page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, parsId, page, items]);

	return {
		data: data || ([] as IVersion[] as T),
		loading,
		loadingCTA,
		setData: setData as stateSetter<T>,
		editVersion,
		getVersions,
		setRefresh,
		items,
		page,
		setItems,
		setPage,
		totalItems,
		totalPages
	};
}