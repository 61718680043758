import { createContext, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { SocketContextType } from './types';

const defaultSocket: Socket = io('', {
	autoConnect: false
});

export const SocketContext = createContext<SocketContextType>({
	connectSocket: () => { },
	disconnectSocket: () => { },
	// setSocket: () => {},
	socket: defaultSocket,
	authenticateSocket: () => { },
	fetchRooms: () => { },
	joinRoom: () => { },
	leaveRoom: () => { },
	sendMessageToRoom: () => Promise.resolve(0),
	leaveAllRooms: () => { },
	blockUserEmit: () => { },
	deleteEmit: () => { },
	sendAttachmentsToRoom: () => Promise.resolve(0),
	deleteProjectEmit: () => { },
	addProjectEmit: () => { },
	fetchQueryRooms: () => { },
	setIsAuthenticated: () => { },
	isAuthenticated: false,
	restrictEmit: () => { },
	postThreadToRoom: () => Promise.resolve(undefined),
	postReplyToThread: () => Promise.resolve(undefined)
});

export const useSocketContext = (): SocketContextType => useContext(SocketContext);
