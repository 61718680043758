import { createContext, useContext } from 'react';
import { IChatContext } from './types';
import { IThread } from '@/hooks';
import { ThreadsReturnType } from '@/hooks/fetch-hooks/use-threads/types';
import { SingleThreadReturnType } from './useSingleThread';

export const ChatContext = createContext<IChatContext>({
	chatRooms: [],
	fetchChatRooms: () => { },
	loading: false,
	selectedRoom: null,
	setSelectedRoom: () => { },
	selectRoom: () => { },
	loadingMessages: false,
	roomMessages: null,
	setRoomMessages: () => { },
	deselectRoom: () => { },
	sendRoomMessage: () => { },
	hasUserList: false,
	setHasUserList: () => { },
	selectedUser: null,
	setSelectedUser: () => { },
	setUserTab: () => { },
	userTab: 1,
	userList: null,
	getUserList: () => { },
	loadingUsers: false,
	// eslint-disable-next-line
	setSearch: () => () => { },
	setSearchUserParam: () => { },
	interestUserId: null,
	setInterestUserId: () => { },
	loadingUserDetails: true,
	restrictLoading: false,
	handleBlock: () => { },
	handleBlockSuccess: () => { },
	handleMute: () => { },
	handleMuteSuccess: () => { },
	handleUnblock: () => { },
	handleUnblockSuccess: () => { },
	handleUnmute: () => { },
	handleUnmuteSuccess: () => { },
	selectedProject: null,
	setSelectedProject: () => { },
	loadingProjectMessages: false,
	projectMessages: null,
	clearRooms: () => { },
	addMediaMessage: () => { },
	loadingMedia: false,
	removeMedia: () => { },
	media: null,
	setMedia: () => { },
	openMedia: false,
	setOpenMedia: () => { },
	// eslint-disable-next-line
	setSearchProjectMessage: () => () => { },
	setSearchMessage: () => { },
	projects: null,
	handleRedirectMessage: () => { },
	redirectMessage: null,
	setRedirectMessage: () => { },
	isAuthenticated: false,
	handleLoadMore: () => { },
	hasMore: false,
	items: 15,
	loadingMore: false,
	page: 0,
	setHasMore: () => { },
	setItems: () => { },
	setPage: () => { },
	scrollMyMessage: false,
	setScrollMyMessage: () => { },
	handleUnseen: () => Promise.resolve(),
	useThreads: {} as ThreadsReturnType<IThread[]>,
	useSingleThread: {} as SingleThreadReturnType,
	administrationRooms: [],
	setAdministrationRooms: () => { },
	selectedModeratorRoom: null,
	selectModeratorRoom: () => { },
	sendThreadsMessage: () => Promise.resolve(),
	loadingPost: false,
	loadingThreads: false,
	threadPage: 0,
	threadItems: 10,
	setThreadPage: () => { },
	setThreadItems: () => { },
	hasMoreThreads: false,
	handleLoadMoreThreads: () => Promise.resolve(),
	loadingMoreThreads: false,
	setHasMoreThreads: () => { },
	sendReplies: () => Promise.resolve(),
	setUserListType: () => { },
	userListType: 'project',
	getAttachments: () => Promise.resolve([]),
});

export const useChatContext = (): IChatContext => useContext(ChatContext);
