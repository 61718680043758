import { Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { FC } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { SimpleDialogTitle } from './styled';
import { ISimpleDialogProps } from './types';
import { Flex } from '../flex';

const Transition = React.forwardRef((
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export const SimpleDialogTransition: FC<ISimpleDialogProps> = ({
	open,
	setOpen,
	customClose,
	title,
	children,
	ctaSection,
	titleProps,
	maxWidth = '800px',
	maxHeight = '100%',
	secondTitle,
	fastClose = false,
	width = 'auto',
}) => {

	const handleClose = (): void => {
		customClose && customClose();
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="delete-dialog-description"
			PaperProps={{
				sx: {
					maxWidth: maxWidth || '800px',
					maxHeight: maxHeight || '100%',
					borderRadius: '24px',
					width: width || 'auto',
				}
			}}
		>
			{fastClose &&
				<Flex sx={{ position: 'absolute', top: 10, right: 16, cursor: 'pointer', zIndex: 999 }}
					onClick={() => handleClose()}
				>
					<CloseOutlined sx={{ width: 36, height: 36 }} />
				</Flex>
			}
			<Flex>
				<SimpleDialogTitle style={{ paddingBottom: secondTitle ? 0 : 'inherit' }} {...titleProps}>
					{title}
				</SimpleDialogTitle>
				{secondTitle && <SimpleDialogTitle style={{ paddingTop: 0, paddingBottom: 0 }} {...titleProps}>
					{secondTitle}
				</SimpleDialogTitle>}
			</Flex>
			<DialogContent >
				{children}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
				{ctaSection}
			</DialogActions>
		</Dialog>
	);
};