/* eslint-disable */
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import {
	IAdministrationRoom,
	IAttachments,
	IChatContext,
	IChatRoom,
	ILastMessage,
	IMessage,
	ISelectedChatUser,
	ISubscribedProjects,
	IUserList
} from './types';
import { ChatContext } from './context';
import { useSocketContext } from '../socket';
import { del, get, notify } from '@/utils';
import { AuthState, useAuthContext } from '../auth';
import { IRestrictObj, IThread, useFileUploader, useRestrictions, useThreads as ThreadBaseHook, IThreadReply } from '@/hooks';
import { initialSimpleFilter, useFiltersContext } from '../filters-provider/context';
import { ISimpleFilter } from '../filters-provider/types';
import { IChatQuery } from '../socket/types';
import {
	fetchActionChatRooms, fetchActionQueryChatRooms, fetchActionRoomMessages, fetchActionRoomThreads,
	fetchUpdateAdminisytrationRoom, fetchUpdateRoom, postUnreadRoom
} from './actions';
import { AxiosError } from 'axios';
import { useMediaQuery, useTheme } from '@mui/material';
import { postFiles } from '@/hooks/fetch-hooks/use-file-uploader/actions';
import { useSingleThread as BaseUseSingleThread } from './useSingleThread';
interface ILoadingUsers {
	all: boolean;
	details: boolean;
}

const sortRoomsByLastMessage = (rooms: IChatRoom[]): IChatRoom[] =>
	rooms.sort((a, b) => {
		const aDate = a.lastMessage?.createdAt ? new Date(a.lastMessage.createdAt).getTime() : 0;
		const bDate = b.lastMessage?.createdAt ? new Date(b.lastMessage.createdAt).getTime() : 0;
		return bDate - aDate;
	});

const sortAdministrationRoomsByLastMessage = (rooms: IAdministrationRoom[]): IAdministrationRoom[] =>
	rooms.sort((a, b) => {
		const aDate = a.lastInteraction ? new Date(a.lastInteraction).getTime() : 0;
		const bDate = b.lastInteraction ? new Date(b.lastInteraction).getTime() : 0;
		return bDate - aDate;
	});

const formatSimpleFilter = (simpleFilter: ISimpleFilter): IChatQuery => {
	const formattedFilter: IChatQuery = {};

	if (simpleFilter.search) {
		formattedFilter.search = simpleFilter.search;
	}
	if (simpleFilter.status === 'published' || simpleFilter.status === 'archived') {
		formattedFilter.status = simpleFilter.status;
	}
	if (simpleFilter.county) {
		formattedFilter.county = simpleFilter.county;
	}
	if (simpleFilter.uat) {
		formattedFilter.uat = simpleFilter.uat;
	}
	if (simpleFilter.seen !== null) {
		formattedFilter.seen = simpleFilter.seen;
	}

	return formattedFilter;
};

export const ChatProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(550));
	const {
		socket,
		joinRoom,
		leaveRoom,
		blockUserEmit,
		sendMessageToRoom,
		leaveAllRooms,
		deleteEmit,
		sendAttachmentsToRoom,
		isAuthenticated,
		postThreadToRoom,
		postReplyToThread
	} = useSocketContext();
	const { simpleFilter, setApplyFilter, setClearFilter, setSimpleFilter } = useFiltersContext();

	const [chatRooms, setChatRooms] = useState<IChatRoom[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedRoom, setSelectedRoom] = useState<IChatRoom | null>(null);
	const [roomMessages, setRoomMessages] = useState<IMessage[] | null>(null);
	const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
	const [hasUserList, setHasUserList] = useState<boolean>(false);
	const [interestUserId, setInterestUserId] = useState<string | null>(null);
	const [selectedUser, setSelectedUser] = useState<ISelectedChatUser | null>(null);
	const [userTab, setUserTab] = useState<number>(1);
	const [loadingUsers, setLoadingUsers] = useState<ILoadingUsers>({ all: true, details: true });
	const [userList, setUserList] = useState<IUserList[] | null>(null);
	const [searchUserParam, setSearchUserParam] = useState<string | null>('');
	const [userListType, setUserListType] = useState<'project' | 'administration'>('project');
	// projects
	const [selectedProject, setSelectedProject] = useState<ISubscribedProjects | null>(null);
	const [projects, setProjects] = useState<ISubscribedProjects[] | null>(null);
	const [loadingProjectMessages, setLoadingProjectMessages] = useState<boolean>(false);
	const [searchMessage, setSearchMessage] = useState<string | null>('');
	const [projectMessages, setProjectMessages] = useState<IMessage[] | null>(null);
	// media
	const { addFiles, removeFile, loading: loadingMedia } = useFileUploader<IAttachments[]>();
	const [media, setMedia] = useState<IAttachments[] | null>(null);
	const [openMedia, setOpenMedia] = useState<boolean>(false);
	const [redirectMessage, setRedirectMessage] = useState<string | null>(null);
	// pagination
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(15);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [loadingMore, setLoadingMore] = useState<boolean>(false);
	const [scrollMyMessage, setScrollMyMessage] = useState<boolean>(false);
	// thread
	const useThreads = ThreadBaseHook<IThread[]>();
	const [administrationRooms, setAdministrationRooms] = useState<IAdministrationRoom[]>([]);
	const [selectedModeratorRoom, setSelectedModeratorRoom] = useState<IAdministrationRoom | null>(null);
	const [threadPage, setThreadPage] = useState<number>(0);
	const [threadItems, setThreadItems] = useState<number>(5);
	const [hasMoreThreads, setHasMoreThreads] = useState<boolean>(false);
	const [loadingMoreThreads, setLoadingMoreThreads] = useState<boolean>(false);
	const useSingleThread = BaseUseSingleThread();

	const {
		setThreadsData, threadFiles, loadingPost, loadingThreads, setLoadingThreads, setLoadingPost,
		setIsEditingId, setStartPost, setThreadEditFiles, setThreadFiles, replyFiles, setReplyFiles,
		setLoadingPostReply
	} = useThreads;

	const { selectedThread, setSelectedThread, setRepliesData, setSingleThreadData } = useSingleThread;

	const {
		blockChatUser,
		unblockUser,
		unmuteUser,
		loading: restrictLoading,
		muteChatUser
	} = useRestrictions();
	const { user, refreshAuthState } = useAuthContext();

	const getUserProjects = useCallback(async (userId: string) => {
		try {
			const response = await get(`api/protected/chat/rooms?user=${userId}`);
			if (response.status === 200) {
				const data = response.data as unknown as { projects: ISubscribedProjects[] };
				setProjects(data.projects);
			}
		} catch (e) {
			console.log('user error', e);
		}
	}, []);

	useEffect(() => {
		if (selectedUser && selectedUser?._id) {
			getUserProjects(selectedUser?._id);
		} else {
			setProjects(null);
		}
		// eslint-disable-next-line
	}, [selectedUser?._id]);

	useEffect(() => {
		// eslint-disable-next-line
		setApplyFilter(() => () => {
			setLoading(true);
			getQueryRooms(formatSimpleFilter(simpleFilter));
		});
		// eslint-disable-next-line
		setClearFilter(() => () => {
			setSimpleFilter(initialSimpleFilter);
			setLoading(true);
			if (isAuthenticated) fetchChatRooms();
		});
		// eslint-disable-next-line
	}, [simpleFilter, isAuthenticated]);

	const handleUnblockSuccess = (): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: selectedUser?.muted ? 'muted' : 'active',
				blockMuteHistory: [
					{
						event: 'unblock',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				blocked: false
			});
			notify.info('Utilizator deblocat');
		}
	};

	const handleUnblock = (userId: string, onSuccess: () => void): void => {
		unblockUser(userId, onSuccess);
	};

	const handleBlockSuccess = (restrictObj: IRestrictObj): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: 'blocked',
				blockMuteHistory: [
					{
						event: 'block',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
							reason: restrictObj.reason,
							until: restrictObj.until
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				blocked: true
			});
			notify.success('Utilizator blocat');
		}
	};

	const handleBlock = (userId: string, restrictObj: IRestrictObj, onSuccess: () => void): void => {
		blockChatUser(userId, { reason: restrictObj.reason, until: restrictObj.until }, (messages) => {
			blockUserEmit(messages);
			onSuccess && onSuccess();
		});
	};

	// Mute actions
	const handleUnmuteSuccess = (): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: selectedUser?.blocked ? 'blocked' : 'active',
				blockMuteHistory: [
					{
						event: 'unmute',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				muted: false
			});
			notify.info('Utilizator derestricționat');
		}
	};

	const handleUnmute = (userId: string, onSuccess: () => void): void => {
		unmuteUser(userId, onSuccess);
	};

	const handleMuteSuccess = (restrictObj: IRestrictObj): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: selectedUser?.status === 'blocked' ? selectedUser?.status : 'muted',
				blockMuteHistory: [
					{
						event: 'mute',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
							reason: restrictObj.reason,
							until: restrictObj.until
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				muted: true
			});
			notify.success('Utilizator restricționat');
		}
	};

	const handleMute = (userId: string, restrictObj: IRestrictObj, onSuccess: () => void): void => {
		muteChatUser(userId, { reason: restrictObj.reason, until: restrictObj.until }, (messages) => {
			blockUserEmit(messages);
			onSuccess && onSuccess();
		});
	};

	const toggleUserLoading = (key: 'all' | 'details', val: boolean): void => {
		setLoadingUsers((prev) => ({
			...prev,
			[key]: val
		}));
	};

	// eslint-disable-next-line
	useEffect(() => {
		if (socket) {

			socket.on('kick', () => {
				notify.info('Proiectul a fost arhivat!');
				setSelectedRoom((prev) => ({
					...(prev || {} as IChatRoom),
					status: 'archived'
				}))
			});

			socket.on('duplicate-auth', (data: { message: string }) => {
				notify.info(data?.message);
			});

			socket.on('last-message', (data: { room: string, lastMessage: ILastMessage }) => {
				updateRoom(data);
			});

			socket.on('trigger-fetch-room', (data: { room: string }) => {
				updateTriggerRoom(data);
			});
			socket.on('trigger-fetch-administration-room', (data: { room: string }) => {
				updateTriggerAdministrationRoom(data);
			});
			socket.on('remove-rooms', (data: { rooms: string[] }) => {
				removeTriggerRooms(data);
			});
			socket.on('remove-administration-rooms', (data: { rooms: string[] }) => {
				removeTriggerAdministrationRooms(data);
			});
			socket.on('new-message', (data: IMessage) => {
				if (selectedRoom) {
					setRoomMessages((prev) => ([...(prev ? prev : []), data]));
				}
			});
			socket.on('new-thread', (data: IThread) => {
				if (selectedModeratorRoom) {
					setThreadsData((prev) => ([...(prev ? prev : []), data]));
				}
			});
			socket.on('new-reply', (data: IThreadReply) => {
				if (selectedModeratorRoom) {
					setThreadsData((prev) => {
						if (!prev) return []; // Ensure prev is always an array

						return prev.map((thread) => {
							if (thread._id === data.thread) {
								// Ensure replies exist
								const updatedReplies = thread.replies ? [...thread.replies] : [];

								// Remove the first reply if there are already 3 replies
								if (updatedReplies.length >= 3) {
									updatedReplies.shift();
								}

								// Add the new reply at the end
								updatedReplies.push(data as IThreadReply); // Ensure type safety

								return { ...thread, replies: updatedReplies };
							}
							return thread;
						});
					});
				}
				if (selectedThread && selectedThread === data?.thread) {
					setRepliesData((prev) => ([...(prev ? prev : []), data]));
				}
			});
			socket.on('updated-reply', (data: IThreadReply) => {
				if (selectedModeratorRoom) {
					setThreadsData((prev) => {
						if (!prev) return []; // Ensure prev is always an array

						return prev.map((thread) => {
							if (thread._id === data.thread) {
								// Ensure replies exist
								const updatedReplies = thread.replies
									? thread.replies.map(reply =>
										reply._id === data._id ? { ...reply, ...data } : reply
									)
									: [];

								return { ...thread, replies: updatedReplies };
							}
							return thread;
						});
					});
				}
				if (selectedThread && selectedThread === data?.thread) {
					setRepliesData((prev) => {
						if (!prev) return [];
						return prev.map((reply) => {
							if (reply?._id === data?._id) {
								return data;
							}
							return reply;
						})
					});
				}
			});

			socket.on('updated-thread', (data: IThread) => {
				if (selectedModeratorRoom) {
					setThreadsData((prev) => [
						...(prev ? prev.filter((th) => th?._id !== data?._id) : []),
						data
					]);
				}
				console.log('updated-thread check', selectedThread && selectedThread === data?._id);
				if (selectedThread && selectedThread === data?._id) {
					console.log('updated-thread', data);
					setSingleThreadData(data);
				}

			});
			socket.on('remove-message', (messageObj: { message: string }) => {
				const messageId = messageObj.message;
				if (roomMessages) {
					// Find the index of the message to be updated
					const index = roomMessages.findIndex((prevMessage) => {
						const prevMessageId = String(prevMessage._id);
						return prevMessageId === messageId;
					});

					if (index !== -1) {
						const updatedMessages = [...roomMessages];
						updatedMessages[index] = { ...updatedMessages[index], deleted: true };
						// Set the updated array as the new state
						setRoomMessages(updatedMessages);
					} else {
						console.log('Message not found:', messageId);
					}
				}
			});

			socket.on('errors', (error: { statusCode: number; message: string }) => {
				console.error('Socket error:', error);
				notify.error(error?.message);
				setLoading(false);
				if (error?.statusCode === 401 && error?.message === 'Unauthorized') {
					refreshAuthState(AuthState.SignedOut);
				}
			});

			// Clean up listeners when the component unmounts or socket changes
			// eslint-disable-next-line
			return () => {
				socket.off('rooms');
				socket.off('kick');
				socket.off('room');
				socket.off('new-message');
				socket.off('new-thread');
				socket.off('new-reply');
				socket.off('updated-thread');
				socket.off('updated-reply');
				socket.off('errors');
				socket.off('last-message');
				socket.off('trigger-fetch-room');
				socket.off('trigger-fetch-administration-room');
				socket.off('remove-message');
				socket.off('remove-rooms');
				socket.off('duplicate-auth');
			};
		}
		// eslint-disable-next-line
	}, [socket, selectedRoom, roomMessages, isAuthenticated, selectedThread, selectedModeratorRoom]);

	const getRooms = useCallback(
		async () => {
			try {
				setLoading(true);
				const response = await fetchActionChatRooms();
				const sortedRooms = sortRoomsByLastMessage(response?.rooms);
				const sortAdministrationRooms = sortAdministrationRoomsByLastMessage(response?.administrationRooms);
				setChatRooms(sortedRooms);
				setAdministrationRooms(sortAdministrationRooms);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setChatRooms]
	);

	const updateRoom = useCallback(
		async (data: { room: string, lastMessage: ILastMessage }) => {
			try {
				setChatRooms((prevChatRooms) => {
					const updatedRooms = prevChatRooms.map((chatRoom) =>
						chatRoom.id === data?.room ? ({
							...chatRoom, lastMessage: data?.lastMessage,
							unreadMessages: selectedRoom?.id === data?.room ? 0 : chatRoom?.unreadMessages + 1
						}) : chatRoom
					);

					return sortRoomsByLastMessage(updatedRooms);
				});
			} catch (message) {
				notify.error(`${message}`);
			}
		},
		[setChatRooms, selectedRoom]
	);

	const updateTriggerRoom = useCallback(
		async (data: { room: string }) => {
			try {
				const myRoom = await fetchUpdateRoom(data.room);
				if (myRoom) {
					setChatRooms((prevChatRooms) => {
						const roomExists = prevChatRooms.some((chatRoom) => chatRoom.id === data?.room);

						if (roomExists) {
							// Update the existing room
							const updatedRooms = prevChatRooms.map((chatRoom) =>
								chatRoom.id === data?.room ? myRoom : chatRoom
							);
							return sortRoomsByLastMessage(updatedRooms);
						} else {
							// Add the new room to the top
							return sortRoomsByLastMessage([myRoom, ...prevChatRooms]);
						}
					});
				}
			} catch (message) {
				notify.error(`${message}`);
			}
		},
		[setChatRooms, selectedRoom]
	);

	const removeTriggerRooms = useCallback(
		(data: { rooms: string[] }) => {
			setChatRooms((prevChatRooms) => {
				// Filter out rooms that match any id in the data.rooms array
				const updatedRooms = prevChatRooms.filter(
					(chatRoom) => !data.rooms.includes(chatRoom.id)
				);
				// Check if the currently selected room is being removed
				if (selectedRoom && data.rooms.includes(selectedRoom?.id)) {
					setSelectedRoom(null); // Clear the selected room
					notify.info('Ai fost dezasignat de la contul de administrație al acestui proiect!');
				}
				return sortRoomsByLastMessage(updatedRooms);
			});
		},
		[setChatRooms, selectedRoom]
	);

	const getQueryRooms = useCallback(
		async (queryParam: IChatQuery) => {
			try {
				setLoading(true);
				const response = await fetchActionQueryChatRooms(queryParam);
				const sortedRooms = sortRoomsByLastMessage(response);
				setChatRooms(sortedRooms);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setChatRooms]
	);

	const getMessages = useCallback(
		async (roomParam: string) => {
			try {
				setLoadingMessages(true);
				const response = await fetchActionRoomMessages(roomParam, page, items);
				response?.length === items && setHasMore(true);
				setRoomMessages(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingMessages(false);
			}
		},
		// eslint-disable-next-line
		[fetchActionRoomMessages]
	);

	const getPaginatedMessages = useCallback(
		async (roomParam: string, pageParam: number) => {
			try {
				setLoadingMore(true);
				const response = await fetchActionRoomMessages(roomParam, pageParam, items);
				response?.length === items && setHasMore(true);
				setHasMore(response?.length === items);
				setRoomMessages((prev) => ([...response, ...(prev || [])]));
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingMore(false);
			}
		},
		// eslint-disable-next-line
		[fetchActionRoomMessages, items]
	);

	const handleLoadMore = useCallback(() => {
		hasMore && !loadingMore && !loadingMessages && setPage((prevPage) => prevPage + 1);
	}, [hasMore, !loadingMore]);

	useEffect(() => {
		if (selectedRoom && hasMore && !loadingMore && page !== 0) {
			getPaginatedMessages(selectedRoom?.id, page)
		}
		// eslint-disable-next-line
	}, [page])

	const fetchChatRooms = useCallback(() => {
		try {
			getRooms();
		} catch (e) {
			console.error('Failed to fetch chat rooms:', e);
			setLoading(false);
		}
	}, [getRooms]);

	useEffect(() => {
		selectedRoom && getMessages(selectedRoom?.id);
	}, [selectedRoom])

	const sendRoomMessage = useCallback(
		async (room: string, message: string) => {
			try {
				const res = await sendMessageToRoom(room, message);
				if (res !== 201) {
					notify.error('Nu am reusit sa trimitem mesajul');
				}
			} catch (err) {
				const error = err as AxiosError;
				const data = error.response?.data as { error: string };
				notify.error(data?.error || 'Nu am reusit sa trimitem mesajul');
			}
		},
		[sendMessageToRoom]
	);

	const setSearch = useCallback((newString: string) => {
		const updateSearchParam = debounce((searchValue: string) => {
			if (searchValue.length > 2) {
				setSearchUserParam(searchValue);
			} else {
				setSearchUserParam(null);
			}
		}, 300);

		updateSearchParam(newString);
		// eslint-disable-next-line
		return () => {
			updateSearchParam.cancel();
		};
	}, []);

	const setSearchProjectMessage = useCallback((newString: string) => {
		const updateSearchParam = debounce((searchValue: string) => {
			if (searchValue.length > 2) {
				setSearchMessage(searchValue);
			} else {
				setSearchMessage(null);
			}
		}, 300);

		updateSearchParam(newString);
		// eslint-disable-next-line
		return () => {
			updateSearchParam.cancel();
		};
	}, []);

	const selectRoom = useCallback(
		(room: IChatRoom | null, isRedirect?: boolean) => {
			selectedRoom && leaveRoom(selectedRoom?.id);
			isRedirect !== true && setHasUserList(false);
			setRoomMessages(null);
			setLoadingMessages(true);
			setSearchUserParam(null);
			setSearchMessage(null);
			setUserListType('project');
			setPage(0);
			setHasMore(false);
			setLoadingMore(false);
			isRedirect !== true && setSelectedUser(null);
			isRedirect !== true && setInterestUserId(null);
			isRedirect !== true && setSelectedProject(null);
			isRedirect !== true && setProjectMessages(null);
			isRedirect !== true && setRedirectMessage(null);
			setMedia(null);
			room && joinRoom(room.id);
			setSelectedRoom(room ? { ...room, unreadMessages: 0 } : room);
			setChatRooms((prevChatRooms) => {
				const updatedRooms = prevChatRooms.map((chatRoom) =>
					chatRoom.id === room?.id ? ({
						...chatRoom, unreadMessages: 0
					}) : chatRoom
				);

				return sortRoomsByLastMessage(updatedRooms);
			});
			setSelectedThread(undefined);
		},
		[joinRoom, selectedRoom]
	);

	const deselectRoom = useCallback(
		(room: string) => {
			leaveRoom(room);
			setSearchUserParam(null);
			setPage(0);
			setHasMore(false);
			setLoadingMore(false);
			setSearchMessage(null);
			setSelectedRoom(null);
			setSelectedUser(null);
			setInterestUserId(null);
			setRoomMessages([]);
			setSelectedProject(null);
			setProjectMessages(null);
		},
		[leaveRoom]
	);

	const clearRooms = useCallback(() => {
		leaveAllRooms();
		setSearchUserParam(null);
		setSearchMessage(null);
		setSelectedRoom(null);
		setSelectedUser(null);
		setInterestUserId(null);
		setRoomMessages([]);
		setSelectedProject(null);
		setProjectMessages(null);
		setPage(0);
		setHasMore(false);
		setLoadingMore(false);
		setItems(15);
	}, [leaveAllRooms]);

	const getUserList = useCallback(async (gueryParam: string) => {
		try {
			toggleUserLoading('all', true);
			const response = await get(`api/chat/users?${gueryParam}`);
			if (response.status === 200) {
				const data = response.data as unknown as { users: ISelectedChatUser[] };
				setUserList(data.users as IUserList[]);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			toggleUserLoading('all', false);
		}
	}, []);

	const getUserDetails = useCallback(async (userId: string) => {
		try {
			toggleUserLoading('details', true);
			const response = await get(`api/protected/chat/users/${userId}`);
			if (response.status === 200) {
				const data = response.data as unknown as { user: ISelectedChatUser };
				setSelectedUser(data.user);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			toggleUserLoading('details', false);
		}
	}, []);

	const getProjectMessages = useCallback(async (gueryParam: string) => {
		try {
			setLoadingProjectMessages(true);
			const response = await get(`api/protected/chat/messages?${gueryParam}`);
			if (response.status === 200) {
				const data = response.data as unknown as { messages: IMessage[] };
				setProjectMessages(data.messages);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			setLoadingProjectMessages(false);
		}
	}, []);

	const deleteProjectMessage = useCallback(
		async (messageId: string, onSuccess?: () => void) => {
			try {
				setLoadingProjectMessages(true);
				const response = await del(`api/protected/chat/messages/${messageId}`);
				if (response.status === 200) {
					setProjectMessages(
						() =>
							projectMessages?.map((message) =>
								message?._id === messageId ? { ...message, deleted: true } : message
							) || []
					);
					onSuccess && onSuccess();
					deleteEmit(messageId);
				}
			} catch (e) {
				console.log('user error', e);
			} finally {
				setLoadingProjectMessages(false);
			}
		},
		// eslint-disable-next-line
		[projectMessages]
	);

	const handleSendAttachmentsToRoom = useCallback(
		async (room: string, attachments: IAttachments[]) => {
			try {
				const res = await sendAttachmentsToRoom(room, attachments);
				if (res !== 201) {
					notify.error('Nu am reusit sa trimitem mesajul');
					setMedia(null);
					setOpenMedia(false);
				}
				if (res === 201) {
					setScrollMyMessage(true);
					setMedia(null);
					setOpenMedia(false);
				}
			} catch (err) {
				const error = err as AxiosError;
				const data = error.response?.data as { error: string };
				notify.error(data?.error || 'Nu am reusit sa trimitem mesajul');
				setMedia(null);
				setOpenMedia(false);
			}
		},
		[sendMessageToRoom]
	);

	const addMediaMessage = (): void => {
		selectedRoom && media && handleSendAttachmentsToRoom(selectedRoom.id, media);
		setMedia(null);
		setOpenMedia(false);
	};

	const removeMedia = (): void => {
		if (!media || media.length === 0) return;

		const removeNext = (index: number): void => {
			if (index >= media.length) {
				setMedia(null);
				setOpenMedia(false);
				return;
			}
			removeFile(media[index].url, () => removeNext(index + 1));
		};

		removeNext(0);
	};

	const handleUnseen = useCallback(
		async (room: string) => {
			clearRooms();
			try {
				const res = await postUnreadRoom(room);
				console.log('unseen response', res);
			} catch (err) {
				console.error('error updating unseen room', err);
			}
		}
		, []);

	useEffect(() => {
		if (selectedRoom && hasUserList) {
			const query =
				searchUserParam && searchUserParam?.length > 2
					? `room=${selectedRoom.id}&search=${searchUserParam}&type=${userListType}`
					: `room=${selectedRoom.id}&type=${userListType}`;
			getUserList(query);
		}
		if (selectedModeratorRoom && hasUserList) {
			const query =
				searchUserParam && searchUserParam?.length > 2
					? `room=${selectedModeratorRoom.id}&search=${searchUserParam}&type=${userListType}`
					: `room=${selectedModeratorRoom.id}&type=${userListType}`;
			getUserList(query);
		}
		// eslint-disable-next-line
	}, [selectedRoom, hasUserList, searchUserParam, userListType, selectedModeratorRoom]);

	useEffect(() => {
		if (interestUserId) {
			getUserDetails(interestUserId);
		} else {
			setSelectedUser(null);
		}
		// eslint-disable-next-line
	}, [interestUserId]);

	// fetch project messages
	useEffect(() => {
		if (selectedProject && selectedUser) {
			const query =
				searchMessage && searchMessage?.length > 2
					? `room=${selectedProject?._id}&user=${selectedUser?._id}&search=${searchMessage}`
					: `room=${selectedProject?._id}&user=${selectedUser?._id}`;
			getProjectMessages(query);
		}
		// eslint-disable-next-line
	}, [selectedProject, selectedUser, searchMessage]);

	const handleRedirectMessage = (redirectId: string): void => {
		isTabletOrSmaller && setHasUserList(false);
		setRedirectMessage(redirectId);
		if (selectedRoom?.id !== selectedProject?._id) {
			const interestRoom = chatRooms?.find((r) => r?.id === selectedProject?._id);
			interestRoom && selectRoom(interestRoom, true);
		}
	};

	// THREADS

	const sendThreadsMessage = useCallback(
		async (room: string, title: string, body: string, onSuccess?: () => void) => {
			try {
				setLoadingPost(true);
				const convertThreads = (): FileList => {
					const dataTransfer = new DataTransfer();

					threadFiles?.forEach((el) => {
						dataTransfer.items.add(el.file); // Add the File object
					});

					return dataTransfer.files;
				};

				const fileList = convertThreads();
				const fileToSend = await postFiles(fileList);
				const res = await postThreadToRoom(room, title, body, fileToSend);
				if (res) {
					notify.info('Postare trimisă cu succes');
					console.log('Postare trimisă cu succes', res);
					onSuccess && onSuccess();
				}
			} catch (err) {
				const error = err as AxiosError;
				const data = error.response?.data as { error: string };
				notify.error(data?.error || 'Nu am reusit sa trimitem postarea');
			} finally {
				setLoadingPost(false);
			}
		},
		[sendMessageToRoom, threadFiles]
	);

	const selectModeratorRoom = useCallback(
		(room: IAdministrationRoom | null) => {
			setIsEditingId(undefined);
			setStartPost(false);
			setHasUserList(false);
			setUserListType('administration');
			setUserList(null);
			setThreadEditFiles([]);
			setThreadFiles([]);
			setThreadPage(0);
			setThreadsData([]);
			setThreadItems(5);
			setHasMoreThreads(false);
			setLoadingMoreThreads(false);
			room && joinRoom(room.id);
			setSelectedModeratorRoom(room);
			setSelectedThread(undefined);
		},
		[joinRoom,]
	);

	const getThreads = useCallback(
		async (roomParam: string) => {
			try {
				setLoadingThreads(true);
				const response = await fetchActionRoomThreads(roomParam, threadPage, threadItems);
				response?.length === threadItems && setHasMoreThreads(true);
				setThreadsData(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingThreads(false);
			}
		},
		// eslint-disable-next-line
		[fetchActionRoomThreads, threadItems, threadPage]
	);

	useEffect(() => {
		selectedModeratorRoom && getThreads(selectedModeratorRoom?.id);
	}, [selectedModeratorRoom]);

	const updateTriggerAdministrationRoom = useCallback(
		async (data: { room: string }) => {
			try {
				const myRoom = await fetchUpdateAdminisytrationRoom(data.room);
				if (myRoom) {
					setAdministrationRooms((prevChatRooms) => {
						const roomExists = prevChatRooms.some((chatRoom) => chatRoom.id === data?.room);

						if (roomExists) {
							// Update the existing room
							const updatedRooms = prevChatRooms.map((chatRoom) =>
								chatRoom.id === data?.room ? myRoom : chatRoom
							);
							return sortAdministrationRoomsByLastMessage(updatedRooms);
						} else {
							// Add the new room to the top
							return sortAdministrationRoomsByLastMessage([myRoom, ...prevChatRooms]);
						}
					});
				}
			} catch (message) {
				notify.error(`${message}`);
			}
		},
		[setChatRooms, selectedRoom]
	);

	const removeTriggerAdministrationRooms = useCallback(
		(data: { rooms: string[] }) => {
			setAdministrationRooms((prevChatRooms) => {
				// Filter out rooms that match any id in the data.rooms array
				const updatedRooms = prevChatRooms.filter(
					(chatRoom) => !data.rooms.includes(chatRoom.id)
				);
				// Check if the currently selected room is being removed
				if (selectedRoom && data.rooms.includes(selectedRoom?.id)) {
					setSelectedRoom(null); // Clear the selected room
				}
				return sortAdministrationRoomsByLastMessage(updatedRooms);
			});
		},
		[setChatRooms, selectedRoom]
	);

	const getPaginatedThreads = useCallback(
		async (roomParam: string, pageParam: number) => {
			try {
				setLoadingMoreThreads(true);
				const response = await fetchActionRoomThreads(roomParam, pageParam, threadItems);
				console.log('page thread response', response?.length === threadItems)
				setHasMoreThreads(response?.length === threadItems);
				setThreadsData((prev) => ([...response, ...(prev || [])]));
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingMoreThreads(false);
			}
		},
		// eslint-disable-next-line
		[fetchActionRoomThreads, threadItems]
	);

	const handleLoadMoreThreads = useCallback(() => {
		console.log('handle load more threads', threadPage);
		if (hasMoreThreads && !loadingMoreThreads && !loadingThreads) {
			// setLoadingThreads(true);
			setThreadPage((prevPage) => prevPage + 1);
		};
	}, [hasMoreThreads, loadingMoreThreads, loadingThreads]);

	useEffect(() => {
		if (selectedModeratorRoom && hasMoreThreads && !loadingMoreThreads && threadPage !== 0) {
			getPaginatedThreads(selectedModeratorRoom?.id, threadPage)
		}
		// eslint-disable-next-line
	}, [threadPage])

	// RELPIES
	const sendReplies = useCallback(
		async (thread: string, body: string, onSuccess?: (atach?: IAttachments[]) => void) => {
			try {
				setLoadingPostReply(true);
				const convertThreads = (): FileList => {
					const dataTransfer = new DataTransfer();

					replyFiles?.forEach((el) => {
						dataTransfer.items.add(el.file); // Add the File object
					});

					return dataTransfer.files;
				};

				const fileList = convertThreads();
				const fileToSend = await postFiles(fileList);
				console.log('reply attachments to send', fileList)

				const res = await postReplyToThread(thread, body, fileToSend);
				if (res === 201) {
					notify.info('Răspuns trimisa cu succes');
					console.log('Răspuns trimisa cu succes', res);
					setReplyFiles([]);
					onSuccess && onSuccess(fileToSend);
				}
			} catch (err) {
				const error = err as AxiosError;
				const data = error.response?.data as { error: string };
				notify.error(data?.error || 'Nu am reusit sa trimitem răspunsul');
			} finally {
				setLoadingPostReply(false);
			}
		},
		[postReplyToThread, replyFiles]
	);

	const getAttachments = useCallback(
		async (searchParam: string): Promise<IAttachments[]> => {
			try {
				if (!selectedModeratorRoom) return [];
				const param: string = selectedThread ? `&thread=${selectedThread}&room=${selectedModeratorRoom?.id}` : `&room=${selectedModeratorRoom?.id}`;
				const res = await get(`api/chat/attachments?search=${searchParam}&page=all&items=all${param}`);
				const data = res.data as { attachments: IAttachments[] };
				return (data.attachments || []);
			} catch (e) {
				const error = e as AxiosError;
				const data = error.response?.data as { error: string };
				throw new Error(data?.error || 'Nu am reușit să accesăm fișierele.');
			}
		},
		[selectedThread, selectedModeratorRoom]
	);

	const value: IChatContext = useMemo(
		() => ({
			chatRooms,
			fetchChatRooms,
			loading,
			selectedRoom,
			selectRoom,
			setSelectedRoom,
			loadingMessages,
			roomMessages,
			setRoomMessages,
			deselectRoom,
			sendRoomMessage,
			hasUserList,
			setHasUserList,
			selectedUser,
			setSelectedUser,
			userTab,
			setUserTab,
			getUserList,
			loadingUsers: loadingUsers.all,
			loadingUserDetails: loadingUsers.details,
			userList,
			setSearch,
			setSearchUserParam,
			interestUserId,
			setInterestUserId,
			restrictLoading,
			handleBlock,
			handleBlockSuccess,
			handleMute,
			handleMuteSuccess,
			handleUnblock,
			handleUnblockSuccess,
			handleUnmute,
			handleUnmuteSuccess,
			selectedProject,
			setSelectedProject,
			loadingProjectMessages,
			projectMessages,
			clearRooms,
			loadingMedia,
			addFiles,
			addMediaMessage,
			removeMedia,
			media,
			setMedia,
			openMedia,
			setOpenMedia,
			setSearchMessage,
			setSearchProjectMessage,
			deleteProjectMessage,
			projects,
			handleRedirectMessage,
			redirectMessage,
			setRedirectMessage,
			isAuthenticated,
			handleLoadMore,
			hasMore,
			items,
			loadingMore,
			page,
			setHasMore,
			setItems,
			setPage,
			scrollMyMessage,
			setScrollMyMessage,
			handleUnseen,
			useThreads,
			administrationRooms,
			setAdministrationRooms,
			selectedModeratorRoom,
			selectModeratorRoom,
			sendThreadsMessage,
			loadingPost,
			loadingThreads,
			handleLoadMoreThreads,
			hasMoreThreads,
			loadingMoreThreads,
			setHasMoreThreads,
			setThreadItems,
			setThreadPage,
			threadItems,
			threadPage,
			sendReplies,
			useSingleThread,
			setUserListType,
			userListType,
			getAttachments
		}),
		// eslint-disable-next-line
		[
			chatRooms,
			fetchChatRooms,
			loading,
			selectedRoom,
			selectRoom,
			loadingMessages,
			roomMessages,
			hasUserList,
			selectedUser,
			userTab,
			loadingUsers,
			userList,
			interestUserId,
			restrictLoading,
			selectedProject,
			loadingProjectMessages,
			projectMessages,
			loadingMedia,
			media,
			openMedia,
			projects,
			redirectMessage,
			isAuthenticated,
			hasMore,
			items,
			loadingMore,
			page,
			scrollMyMessage,
			administrationRooms,
			selectedModeratorRoom,
			useThreads,
			loadingPost,
			loadingThreads,
			hasMoreThreads,
			threadItems,
			loadingMoreThreads,
			threadPage,
			useSingleThread,
			userListType,
		]
	);

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
