import { AxiosError } from 'axios';
import { edit, get } from '@/utils';
import { IEditReply, IEditThread, IThread } from './types';

async function patchThread(
	threadId: string, updatesObject: IEditThread
): Promise<string | number> {
	try {
		const res = await edit(`api/chat/threads/${threadId}`, { ...updatesObject });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm postarea.');
	}
}

async function patchReply(
	replyId: string, updatesObject: IEditReply
): Promise<string | number> {
	try {
		const res = await edit(`api/chat/replies/${replyId}`, { ...updatesObject });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm răspunsul.');
	}
}

async function fetchSingleThread(
	threadId: string,
): Promise<IThread> {
	try {
		const res = await get(`api/chat/threads/${threadId}`);
		const data = res.data as unknown as { thread: IThread };
		return (data.thread || {});
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm postarea.');
	}
};

export { patchThread, patchReply, fetchSingleThread };