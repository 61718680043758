import { useCallback, useEffect, useState } from 'react';
import { IEditReply, IEditThread, IThread, ThreadsReturnType, UploadedThreadFile } from './types';
import { notify, stateSetter } from '@/utils';
import { fetchSingleThread, patchReply, patchThread } from './actions';
import { postFiles } from '../use-file-uploader/actions';

export function useThreads<T extends IThread | IThread[]>(
	thrId: string | undefined = undefined,
): ThreadsReturnType<T> {
	const [threadsData, setThreadsData] = useState<T | null>(null);
	const [threadFiles, setThreadFiles] = useState<UploadedThreadFile[]>([]);
	const [threadEditFiles, setThreadEditFiles] = useState<UploadedThreadFile[]>([]);
	const [startPost, setStartPost] = useState<boolean>(false);
	const [loadingPost, setLoadingPost] = useState<boolean>(false);
	const [loadingThreads, setLoadingThreads] = useState<boolean>(false);
	const [loadingEditThreads, setLoadingEditThreads] = useState<boolean>(false);
	const [isEditingId, setIsEditingId] = useState<string | undefined>();

	const [replyFiles, setReplyFiles] = useState<UploadedThreadFile[]>([]);
	const [replyEditFiles, setReplyEditFiles] = useState<UploadedThreadFile[]>([]);
	const [loadingPostReply, setLoadingPostReply] = useState<boolean>(false);
	const [loadingEditReply, setLoadingEditReply] = useState<boolean>(false);
	const [hasUserList, setHasUserList] = useState<boolean>(false);

	const removeThreadFile = (id: string): void => {
		setThreadFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	};

	const removeEditedThreadFile = (id: string): void => {
		setThreadEditFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	};

	const removeReplyFile = (id: string): void => {
		setReplyFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	};

	const removeEditedReplyFile = (id: string): void => {
		setReplyEditFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	};

	const editThread = useCallback(
		async (threadId: string, editObj: IEditThread, onSuccess?: (newVal: IEditThread) => void) => {
			try {
				setLoadingEditThreads(true);
				const convertThreads = (): FileList => {
					const dataTransfer = new DataTransfer();

					threadEditFiles?.forEach((el) => {
						dataTransfer.items.add(el.file); // Add the File object
					});

					return dataTransfer.files;
				};

				const fileList = convertThreads();
				const fileToSend = await postFiles(fileList);
				const updateObject: IEditThread = {
					...editObj,
					attachments: [
						...((editObj.attachments || []).map((el) => ({
							url: el.url,
							mimetype: el.mimetype,
							originalname: el.originalname,
							size: el.size,
						}))),
						...fileToSend,
					]
				};
				const response = await patchThread(threadId, updateObject);
				if (response === 200) {
					onSuccess && onSuccess(updateObject);
				};
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingEditThreads(false);
			}
		},
		[threadEditFiles]
	);

	const editReply = useCallback(
		async (replyId: string, editObj: IEditReply, onSuccess?: (newVal: IEditReply) => void) => {
			try {
				setLoadingEditReply(true);
				const convertThreads = (): FileList => {
					const dataTransfer = new DataTransfer();

					replyEditFiles?.forEach((el) => {
						dataTransfer.items.add(el.file); // Add the File object
					});

					return dataTransfer.files;
				};

				const fileList = convertThreads();
				const fileToSend = await postFiles(fileList);
				const updateObject: IEditReply = {
					...editObj,
					attachments: [
						...((editObj.attachments || []).map((el) => ({
							url: el.url,
							mimetype: el.mimetype,
							originalname: el.originalname,
							size: el.size,
						}))),
						...fileToSend,
					]
				};
				const response = await patchReply(replyId, updateObject);
				if (response === 200) {
					onSuccess && onSuccess(updateObject);
				};
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingEditReply(false);
			}
		},
		[replyEditFiles]
	);

	const getThread = useCallback(
		async (roomParam: string) => {
			try {
				setLoadingThreads(true);
				const response = await fetchSingleThread(roomParam);
				setThreadsData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingThreads(false);
			}
		},
		// eslint-disable-next-line
		[fetchSingleThread]
	);

	useEffect(() => {
		thrId && getThread(thrId);
		// eslint-disable-next-line
	}, [thrId]);

	return {
		threadsData: threadsData || ([] as IThread[] as T),
		setThreadsData: setThreadsData as stateSetter<T>,
		threadFiles,
		setThreadFiles,
		removeThreadFile,
		loadingPost,
		loadingThreads,
		setLoadingPost,
		setLoadingThreads,
		loadingEditThreads,
		setLoadingEditThreads,
		editThread,
		setThreadEditFiles,
		threadEditFiles,
		isEditingId,
		setIsEditingId,
		setStartPost,
		startPost,
		removeEditedThreadFile,
		replyEditFiles,
		replyFiles,
		setReplyEditFiles,
		setReplyFiles,
		removeEditedReplyFile,
		removeReplyFile,
		loadingPostReply,
		setLoadingPostReply,
		editReply,
		loadingEditReply,
		setLoadingEditReply,
		hasUserList,
		setHasUserList
	};
}