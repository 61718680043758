import { useCallback, useEffect, useState } from 'react';
import { FeedbackReturnType, FeedbackStatus, FeedbackType, IFeedback } from './types';
import { notify, stateSetter } from '@/utils';
import { deleteFeedback, fetchFeedback, fetchFeedbackQuery, fetchSingleFeedback, patchFeedback } from './actions';

export function useFeedback<T extends IFeedback | IFeedback[]>(
	typeParam: FeedbackType | undefined = undefined,
	parsId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): FeedbackReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalItems, setTotalItems] = useState<number>(1);

	const handleResponse = (pageParam: number, itemsParam: number): void => {
		setTotalPages(pageParam);
		setTotalItems(itemsParam);
	};

	const getFeedbacks = useCallback(
		async (type: FeedbackType, pageParam: number, itemsParam: number) => {
			try {
				setLoading(true);
				const response = await fetchFeedback(type, pageParam, itemsParam, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getVersionsQuery = useCallback(
		async (query: string, queryPage: number, queryItems: number) => {
			try {
				setLoading(true);
				const response = await fetchFeedbackQuery(query, queryPage, queryItems, handleResponse);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getSingleFeedback = useCallback(
		async (id: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleFeedback(id);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeFeedback = useCallback(
		async (feedbackId: string, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await deleteFeedback(feedbackId);
				if (response === 200) {
					const temp = Array.isArray(data) ? data.map(feedback =>
						feedback._id === feedbackId ? { ...feedback, deleted: true } : feedback
					) : data;
					setData(temp as T);
					onSuccess && onSuccess();
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData, data]
	);

	const editFeedback = useCallback(
		async (id: string, status: FeedbackStatus, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await patchFeedback(id, status);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să actualizăm datele');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (typeParam && queryParam === undefined) {
			getFeedbacks(typeParam, page, items);
		}
		// eslint-disable-next-line
	}, [typeParam, queryParam, page, items]);

	useEffect(() => {
		if (parsId) {
			getSingleFeedback(parsId);
		}
		// eslint-disable-next-line
	}, [parsId]);

	useEffect(() => {
		if (typeParam && queryParam && parsId === undefined) {
			getVersionsQuery(queryParam, page, items);
		}
		// eslint-disable-next-line
	}, [typeParam, queryParam, parsId, page, items]);

	return {
		data: data || ([] as IFeedback[] as T),
		setData: setData as stateSetter<T>,
		getFeedbacks,
		loading,
		loadingCTA,
		editFeedback,
		removeFeedback,
		items,
		page,
		setItems,
		setPage,
		totalItems,
		totalPages
	};
}